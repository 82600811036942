import { ChangeEventHandler, FC, FocusEventHandler, FormEventHandler, useEffect, useMemo, useState } from 'react';

import { debounce } from 'debounce';
import { useRouter } from 'next/router';
import { ClickAwayListener } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import { captureException } from '@sentry/nextjs';
import { InternalLink } from '../bits/links';
import { PlaceholderText } from '../bits/text';
import { useLocalizer } from '../localization';
import routes from '../routes';
import { isEmpty } from '../utils';
import { CategorySummary } from '../entities/category';
import { searchModels as searchModelsDeprecated } from '../providers/models';
import { Model } from '../entities/model';
import { pickCategoriesFromModels } from '../providers/models/utils';
import { useFullStory } from '../hooks/useFullStory/useFullStory';
import { CustomEvents } from '../hooks/useFullStory/customEvents';
import { SearchFlowType } from '../marketplace/search/types';
import { FS__SearchResultClickType } from '../hooks/useFullStory/types';

/**
 * @deprecated - stop this chaos
 */
const dedupeModelResults = (modelResults: Model[]): Model[] => {
  const dedupedModelResults: Map<number, Model> = new Map();

  for (const modelResult of modelResults) {
    if (!dedupedModelResults.has(modelResult.id)) {
      dedupedModelResults.set(modelResult.id, modelResult);
    }
  }

  return [...dedupedModelResults.values()];
};

/**
 * @deprecated - stop this chaos
 */
export const useEquipmentSearch = () => {
  const router = useRouter();
  const loc = useLocalizer();
  const [searchTerm, setSearchTerm] = useState('');
  const [showResults, setShowResults] = useState(false);
  const [modelResults, setModelResults] = useState<Model[]>([]);
  const [categoryResults, setCategoryResults] = useState<CategorySummary[]>([]);
  const { fullStoryEvent } = useFullStory();

  const limit = 10;

  useEffect(() => {
    if (router.isReady) {
      const q = typeof router.query.q === 'string' ? router.query.q : '';
      setSearchTerm(q);
      setShowResults(false);
    }
  }, [router.isReady, router.query]);

  const debouncedFetchListings = useMemo(
    () =>
      debounce((searchTerm: string) => {
        if (!isEmpty(searchTerm)) {
          searchModelsDeprecated({ q: searchTerm, limit })
            .then((results) => {
              const dedupedResults = dedupeModelResults(results);
              setModelResults(dedupedResults);
              setCategoryResults(pickCategoriesFromModels(dedupedResults));
            })
            .catch((err) => {
              captureException(err, {
                tags: {
                  layout: 'SearchBar',
                  file: 'src/layout/searchBar.tsx'
                },
                extra: {
                  searchTerm: searchTerm
                }
              });
            });
        } else {
          setModelResults([]);
          setCategoryResults([]);
        }
      }, 300),
    []
  );

  const handleSearchChange: ChangeEventHandler<HTMLInputElement> = (event) => {
    const value = event.target.value;
    setSearchTerm(value);
    debouncedFetchListings(value);
    setShowResults(true);
  };

  const handleSearchSubmit: FormEventHandler = (event) => {
    event.preventDefault();
    router.push(routes.search({ search_flow_type: SearchFlowType.PlainText, q: searchTerm }));
  };

  const handleFocus: FocusEventHandler<HTMLInputElement> = () => {
    setShowResults(true);
  };

  const ResultsDropdown: FC = () => {
    if (!showResults || isEmpty(searchTerm)) return null;

    let dropdownItems = (
      <div className="px-4 py-1">
        <PlaceholderText>{loc.Common.NoExactMatches}</PlaceholderText>
      </div>
    );

    if (!isEmpty(modelResults)) {
      dropdownItems = (
        <>
          <div className="gap-x-4 px-4 uppercase text-gray-400">{loc.Nav.Models.toUpperCase()}</div>
          <ul className="mb-2">
            {modelResults.map((model) => (
              <li key={`equipment-search-model-${model.id}`}>
                <InternalLink
                  className="block gap-x-4 bg-white px-4 py-1 hover:brightness-95"
                  href={routes.search({ search_flow_type: SearchFlowType.Model, model_id: [model.id] })}
                  onClick={() => {
                    fullStoryEvent(CustomEvents.search.searchResultClick, {
                      type: FS__SearchResultClickType.Model,
                      id: model.id,
                      name: `${model.make.name} ${model.name}`
                    });
                  }}
                >
                  {model.make.name} {model.name}
                </InternalLink>
              </li>
            ))}
            <li>
              <InternalLink
                className="block px-4 py-1 italic hover:underline"
                href={routes.search({ search_flow_type: SearchFlowType.PlainText, q: searchTerm })}
              >
                {loc.Nav.SeeAllResults}
              </InternalLink>
            </li>
          </ul>

          <div className="gap-x-4 px-4 uppercase text-gray-400">{loc.Nav.Categories}</div>
          <ul>
            {categoryResults.map((category) => (
              <InternalLink
                className="block gap-x-4 bg-white px-4 py-1 hover:brightness-95"
                key={category.id}
                href={routes.search({ search_flow_type: SearchFlowType.Category, category_id: category.id })}
                onClick={() => {
                  fullStoryEvent(CustomEvents.search.searchResultClick, {
                    type: FS__SearchResultClickType.Category,
                    id: category.id,
                    name: category.name
                  });
                }}
              >
                {category.name}
              </InternalLink>
            ))}
          </ul>
        </>
      );
    }

    return (
      <div
        className="absolute left-0 right-0 top-full z-equipmentSearchBar flex max-h-[35vh] w-full flex-col gap-y-1 overflow-y-auto bg-white py-2 shadow-basic lg:max-h-[none] lg:w-max lg:min-w-full lg:max-w-[43rem] lg:overflow-y-hidden lg:rounded"
        data-testid="ResultsDropdown"
      >
        {dropdownItems}
      </div>
    );
  };

  return {
    setShowResults,
    handleSearchSubmit,
    handleSearchChange,
    handleFocus,
    searchTerm,
    ResultsDropdown,
    modelResults,
    categoryResults
  };
};

/**
 *
 */

/**
 * @deprecated - stop this chaos
 */
export const HomeEquipmentSearchBar: FC = () => {
  const { handleSearchSubmit, handleSearchChange, handleFocus, searchTerm, ResultsDropdown, setShowResults } = useEquipmentSearch();
  const loc = useLocalizer();

  return (
    <ClickAwayListener
      onClickAway={() => {
        setShowResults(false);
      }}
    >
      <div className="relative mb-6 w-full lg:mb-16 lg:w-4/5">
        <form onSubmit={handleSearchSubmit} className="flex w-full overflow-hidden rounded lg:rounded-xl">
          <input
            id="searchbar-homepage"
            type="text"
            placeholder={loc.Common.WhatEquipmentAreYouLookingFor}
            className="w-full rounded-l border-3 border-r-0 border-solid border-magenta-500 p-2 font-montserrat text-14spx tracking-wide placeholder-moov-blue-500 placeholder:font-light lg:rounded-l-xl lg:p-4 lg:text-21spx"
            value={searchTerm}
            onChange={handleSearchChange}
            onFocus={handleFocus}
            spellCheck={false}
          />
          <button
            type="submit"
            className="animate-brighten-on-hover bg-magenta-500 px-3 text-24spx text-white lg:px-5 lg:text-40spx"
            aria-label={loc.Common.SearchEquipment}
          >
            <SearchIcon fontSize="inherit" />
          </button>
        </form>
        <ResultsDropdown />
      </div>
    </ClickAwayListener>
  );
};

/**
 * @deprecated - stop this chaos
 */
export interface MarketingEquipmentSearchBarProps {
  inputId: string;
}

/**
 * @deprecated - stop this chaos
 */
export const MarketingEquipmentSearchBar: FC<MarketingEquipmentSearchBarProps> = ({ inputId }) => {
  const { handleSearchSubmit, handleSearchChange, handleFocus, searchTerm, ResultsDropdown, setShowResults } = useEquipmentSearch();
  const loc = useLocalizer();

  return (
    <ClickAwayListener
      onClickAway={() => {
        setShowResults(false);
      }}
    >
      <div className="relative w-full">
        <form className="flex w-full overflow-hidden rounded shadow-basic" onSubmit={handleSearchSubmit}>
          <input
            id={inputId}
            type="text"
            placeholder={loc.Common.SearchByKeywordMakeOrModel}
            className="flex-1 px-3 py-3 text-16spx placeholder-gray-400 lg:px-6"
            value={searchTerm}
            onChange={handleSearchChange}
            onFocus={handleFocus}
            spellCheck={false}
          />
          <button
            type="submit"
            className="animate-brighten-on-hover rounded-l-none bg-magenta-500 px-3 text-12spx tracking-widest text-white lg:px-7 lg:py-4"
          >
            <span className="text-24spx lg:hidden">
              <SearchIcon fontSize="inherit" />
            </span>
            <span className="hidden uppercase lg:inline">{loc.Common.FindIt}</span>
          </button>
        </form>
        <ResultsDropdown />
      </div>
    </ClickAwayListener>
  );
};
