import { CategorySummary } from '../../entities/category';
import { MakeSummary } from '../../entities/make';
import { Model } from '../../entities/model';

export const pickCategoriesFromModels = (models: Model[]): CategorySummary[] => [
  ...new Map(models.map(({ category }) => [category.id, category])).values()
];

export const pickMakesFromModels = (models: Model[]): MakeSummary[] => [...new Map(models.map(({ make }) => [make.id, make])).values()];

export const groupByName = <T extends { name: string }>(items: T[]): [string, T[]][] => {
  const sortedModels = [...items].sort((a, b) => {
    const [A, B] = [a.name.toUpperCase(), b.name.toUpperCase()];
    if (A < B) return -1;
    if (A > B) return 1;
    return 0;
  });

  const groups: Map<string, T[]> = new Map();

  const defaultGroup: T[] = [];
  groups.set('#', defaultGroup);

  for (const model of sortedModels) {
    const value = model.name.slice(0, 1).toUpperCase();
    if (/[A-Z]/.test(value)) {
      let group = groups.get(value);
      if (typeof group === 'undefined') {
        group = [];
        groups.set(value, group);
      }
      group.push(model);
    } else {
      defaultGroup.push(model);
    }
  }

  return [...groups.entries()];
};
