import { FC } from 'react';
import Image from 'next/image';

import { useLocalizer } from '../../../localization';
import { ImageCovered } from '../../../bits/responsiveImage';

interface QuoteWidgetProps {
  readonly imageSrc: string;
  readonly bgImageSrc: string;
  readonly title: string;
  readonly name: string;
  readonly quote: string;
  readonly company?: string;
}

/**
 *
 */
const QuoteWidget: FC<QuoteWidgetProps> = ({ imageSrc, bgImageSrc, title, name, quote, company }) => {
  const loc = useLocalizer();

  return (
    <div className="mx-auto flex max-w-[28rem] flex-col items-center">
      <div className="mb-2.5 flex h-66 w-66 items-center justify-center lg:mb-6.5">
        <div className="absolute h-66 w-66">
          <Image src={bgImageSrc} alt="" layout="fill" objectFit="contain" quality={100} width={180} height={180} />
        </div>
        <ImageCovered
          className="h-43 w-43 overflow-hidden rounded-full border-3 border-magenta-500 lg:border-6"
          src={imageSrc}
          alt={loc.Testimony.HeadshotOf(name)}
        />
      </div>

      <article className="text-center font-inter">
        <p className="mb-3 text-20spx italic leading-tight">“{quote}”</p>
        <p className="text-20spx font-bold leading-snug lg:text-24spx">{name}</p>
        <p className="font-lato text-16spx text-sky-blue-500 lg:text-20spx">{title}</p>
        <p className="text-18spx">{company}</p>
      </article>
    </div>
  );
};

export default QuoteWidget;
