export const TESTIMONIES = [
  {
    name: 'Louis Fan',
    title: 'CEO',
    company: 'Ecelent Technology China Ltd.',
    review: `The transaction processing time is very fast and efficient on the platform. Also, the team is extremely professional in logistics. It's been worry free working with Moov.`,
    imageUrl: 'https://d2pkkbyngq3xpw.cloudfront.net/moov_media/3.0-assets/LouisFan-enhanced.jpg'
  },
  {
    name: 'Earl Weltmer',
    title: 'Owner',
    company: 'Scanservice Corporation',
    review:
      'There is an adage: good, cheap, and reliable; pick any two. With Moov you can add fast. Moov covers all four when buying used equipment. They consistently serve quality equipment at below-market prices. Their logistics process ensures that tools are properly transported and arrive safely at our facility in a transparent and timely manner. Moov’s platform adds value in every aspect.',
    imageUrl: 'https://d2pkkbyngq3xpw.cloudfront.net/moov_media/3.0-assets/EarlWeltmer-enhanced.jpg'
  },
  {
    name: 'Shelton Qiao',
    title: 'President',
    company: 'Sitronics China',
    review: `Moov's website helps me find many good deals from all around the world. They have a good network and community of sellers. Also, they help us on many logistics projects. Overall, very good support.`,
    imageUrl: 'https://d2pkkbyngq3xpw.cloudfront.net/moov_media/3.0-assets/SheltonQiao-enhanced.jpg'
  },
  {
    name: 'Danny Lee',
    title: 'President',
    company: 'CSE Co., Ltd.',
    review: `I am always impressed with the website and team of Moov. The website makes doing business fast and easy, with accurate equipment information. Moov is the future!`,
    imageUrl: 'https://d2pkkbyngq3xpw.cloudfront.net/moov_media/3.0-assets/DannyLee-enhanced.jpg'
  }
];
