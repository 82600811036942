import type { FC } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Pagination } from 'swiper';

import { TESTIMONIES } from '../../constants/testimonies';
import { useLocalizer } from '../../localization';
import { IMAGE_URLS } from '@/src/constants/imageUrls';

import 'swiper/css';
import 'swiper/css/pagination';
import QuoteWidget from '@/src/corporate/careers/components/quoteWidget';

interface TestimonialsCarouselProps {
  readonly colorVariant: 'magenta' | 'green';
}

/**
 *
 */
const TestimonialsCarousel: FC<TestimonialsCarouselProps> = () => {
  const loc = useLocalizer();

  return (
    <div className="hero-carousel relative">
      <Swiper
        modules={[Navigation, Pagination]}
        pagination={{ clickable: true }}
        spaceBetween={48}
        autoHeight
        breakpoints={{
          1024: { slidesPerView: 3, slidesPerGroup: 3 }
        }}
        className="!pb-16"
      >
        {TESTIMONIES.map(({ name, title, company, imageUrl }, index) => (
          <SwiperSlide key={name}>
            <QuoteWidget
              imageSrc={imageUrl}
              bgImageSrc={index % 2 === 0 ? IMAGE_URLS.DECORATIVE_SHAPES_1 : IMAGE_URLS.DECORATIVE_SHAPES_2}
              title={loc.Testimony.Title(title)}
              name={name}
              quote={loc.Testimony.TestimonyOf(name)}
              company={company}
            />
          </SwiperSlide>
        ))}
      </Swiper>
    </div>
  );
};

export default TestimonialsCarousel;
